/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ContentApiModel,
  HomeCarouselItemApiModel,
  OrderConfirmationApiModel,
  ProblemDetails,
  UspApiModel,
} from '../models';
import {
    ContentApiModelFromJSON,
    ContentApiModelToJSON,
    HomeCarouselItemApiModelFromJSON,
    HomeCarouselItemApiModelToJSON,
    OrderConfirmationApiModelFromJSON,
    OrderConfirmationApiModelToJSON,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    UspApiModelFromJSON,
    UspApiModelToJSON,
} from '../models';

export interface ContentHomeCarouselGetRequest {
    exclTax?: boolean;
}

export interface ContentLogoutUrlGetRequest {
    embedded?: boolean;
}

export interface ContentOrderConfirmationGetRequest {
    orderId?: number;
    orderNumber?: string;
}

/**
 * 
 */
export class ContentApi extends runtime.BaseAPI {

    /**
     */
    async contentGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ContentApiModel>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Content`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ContentApiModelFromJSON(jsonValue));
    }

    /**
     */
    async contentGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ContentApiModel> {
        const response = await this.contentGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async contentHomeCarouselGetRaw(requestParameters: ContentHomeCarouselGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<HomeCarouselItemApiModel>>> {
        const queryParameters: any = {};

        if (requestParameters.exclTax !== undefined) {
            queryParameters['exclTax'] = requestParameters.exclTax;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Content/HomeCarousel`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(HomeCarouselItemApiModelFromJSON));
    }

    /**
     */
    async contentHomeCarouselGet(requestParameters: ContentHomeCarouselGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<HomeCarouselItemApiModel>> {
        const response = await this.contentHomeCarouselGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contentHomeUspsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UspApiModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Content/HomeUsps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UspApiModelFromJSON));
    }

    /**
     */
    async contentHomeUspsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UspApiModel>> {
        const response = await this.contentHomeUspsGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async contentLogoGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Content/Logo`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async contentLogoGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.contentLogoGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async contentLogoutUrlGetRaw(requestParameters: ContentLogoutUrlGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderConfirmationApiModel>> {
        const queryParameters: any = {};

        if (requestParameters.embedded !== undefined) {
            queryParameters['embedded'] = requestParameters.embedded;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Content/LogoutUrl`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderConfirmationApiModelFromJSON(jsonValue));
    }

    /**
     */
    async contentLogoutUrlGet(requestParameters: ContentLogoutUrlGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderConfirmationApiModel> {
        const response = await this.contentLogoutUrlGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contentOrderConfirmationGetRaw(requestParameters: ContentOrderConfirmationGetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<OrderConfirmationApiModel>> {
        const queryParameters: any = {};

        if (requestParameters.orderId !== undefined) {
            queryParameters['orderId'] = requestParameters.orderId;
        }

        if (requestParameters.orderNumber !== undefined) {
            queryParameters['orderNumber'] = requestParameters.orderNumber;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Content/OrderConfirmation`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrderConfirmationApiModelFromJSON(jsonValue));
    }

    /**
     */
    async contentOrderConfirmationGet(requestParameters: ContentOrderConfirmationGetRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<OrderConfirmationApiModel> {
        const response = await this.contentOrderConfirmationGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async contentUspsGetRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UspApiModel>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/Content/Usps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UspApiModelFromJSON));
    }

    /**
     */
    async contentUspsGet(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UspApiModel>> {
        const response = await this.contentUspsGetRaw(initOverrides);
        return await response.value();
    }

}
