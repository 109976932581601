/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ActivateAccountApiRequestModel
 */
export interface ActivateAccountApiRequestModel {
    /**
     * 
     * @type {number}
     * @memberof ActivateAccountApiRequestModel
     */
    debtorNumber?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountApiRequestModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountApiRequestModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountApiRequestModel
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountApiRequestModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ActivateAccountApiRequestModel
     */
    lastInvoiceNumber?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivateAccountApiRequestModel
     */
    noInvoice?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ActivateAccountApiRequestModel
     */
    newsletter?: boolean | null;
}

/**
 * Check if a given object implements the ActivateAccountApiRequestModel interface.
 */
export function instanceOfActivateAccountApiRequestModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ActivateAccountApiRequestModelFromJSON(json: any): ActivateAccountApiRequestModel {
    return ActivateAccountApiRequestModelFromJSONTyped(json, false);
}

export function ActivateAccountApiRequestModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ActivateAccountApiRequestModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'debtorNumber': !exists(json, 'debtorNumber') ? undefined : json['debtorNumber'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'lastInvoiceNumber': !exists(json, 'lastInvoiceNumber') ? undefined : json['lastInvoiceNumber'],
        'noInvoice': !exists(json, 'noInvoice') ? undefined : json['noInvoice'],
        'newsletter': !exists(json, 'newsletter') ? undefined : json['newsletter'],
    };
}

export function ActivateAccountApiRequestModelToJSON(value?: ActivateAccountApiRequestModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'debtorNumber': value.debtorNumber,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phoneNumber': value.phoneNumber,
        'email': value.email,
        'lastInvoiceNumber': value.lastInvoiceNumber,
        'noInvoice': value.noInvoice,
        'newsletter': value.newsletter,
    };
}

