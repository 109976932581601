/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProfileExtendedApiModel
 */
export interface ProfileExtendedApiModel {
    /**
     * 
     * @type {number}
     * @memberof ProfileExtendedApiModel
     */
    numberOfOrdersLastMonth?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileExtendedApiModel
     */
    numberOfProductsInOrderHistory?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProfileExtendedApiModel
     */
    wishListsSum?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProfileExtendedApiModel
     */
    latestInvoiceDate?: string | null;
}

/**
 * Check if a given object implements the ProfileExtendedApiModel interface.
 */
export function instanceOfProfileExtendedApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfileExtendedApiModelFromJSON(json: any): ProfileExtendedApiModel {
    return ProfileExtendedApiModelFromJSONTyped(json, false);
}

export function ProfileExtendedApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileExtendedApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'numberOfOrdersLastMonth': !exists(json, 'numberOfOrdersLastMonth') ? undefined : json['numberOfOrdersLastMonth'],
        'numberOfProductsInOrderHistory': !exists(json, 'numberOfProductsInOrderHistory') ? undefined : json['numberOfProductsInOrderHistory'],
        'wishListsSum': !exists(json, 'wishListsSum') ? undefined : json['wishListsSum'],
        'latestInvoiceDate': !exists(json, 'latestInvoiceDate') ? undefined : json['latestInvoiceDate'],
    };
}

export function ProfileExtendedApiModelToJSON(value?: ProfileExtendedApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'numberOfOrdersLastMonth': value.numberOfOrdersLastMonth,
        'numberOfProductsInOrderHistory': value.numberOfProductsInOrderHistory,
        'wishListsSum': value.wishListsSum,
        'latestInvoiceDate': value.latestInvoiceDate,
    };
}

