/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OrderDetailApiModel } from './OrderDetailApiModel';
import {
    OrderDetailApiModelFromJSON,
    OrderDetailApiModelFromJSONTyped,
    OrderDetailApiModelToJSON,
} from './OrderDetailApiModel';
import type { UspApiModel } from './UspApiModel';
import {
    UspApiModelFromJSON,
    UspApiModelFromJSONTyped,
    UspApiModelToJSON,
} from './UspApiModel';

/**
 * 
 * @export
 * @interface OrderConfirmationApiModel
 */
export interface OrderConfirmationApiModel {
    /**
     * 
     * @type {boolean}
     * @memberof OrderConfirmationApiModel
     */
    isSuccess?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof OrderConfirmationApiModel
     */
    canRetry?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationApiModel
     */
    imageUrl?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationApiModel
     */
    title?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationApiModel
     */
    description?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrderConfirmationApiModel
     */
    uspTitle?: string | null;
    /**
     * 
     * @type {Array<UspApiModel>}
     * @memberof OrderConfirmationApiModel
     */
    usps?: Array<UspApiModel> | null;
    /**
     * 
     * @type {OrderDetailApiModel}
     * @memberof OrderConfirmationApiModel
     */
    orderDetails?: OrderDetailApiModel;
}

/**
 * Check if a given object implements the OrderConfirmationApiModel interface.
 */
export function instanceOfOrderConfirmationApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrderConfirmationApiModelFromJSON(json: any): OrderConfirmationApiModel {
    return OrderConfirmationApiModelFromJSONTyped(json, false);
}

export function OrderConfirmationApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): OrderConfirmationApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': !exists(json, 'isSuccess') ? undefined : json['isSuccess'],
        'canRetry': !exists(json, 'canRetry') ? undefined : json['canRetry'],
        'imageUrl': !exists(json, 'imageUrl') ? undefined : json['imageUrl'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'uspTitle': !exists(json, 'uspTitle') ? undefined : json['uspTitle'],
        'usps': !exists(json, 'usps') ? undefined : (json['usps'] === null ? null : (json['usps'] as Array<any>).map(UspApiModelFromJSON)),
        'orderDetails': !exists(json, 'orderDetails') ? undefined : OrderDetailApiModelFromJSON(json['orderDetails']),
    };
}

export function OrderConfirmationApiModelToJSON(value?: OrderConfirmationApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'canRetry': value.canRetry,
        'imageUrl': value.imageUrl,
        'title': value.title,
        'description': value.description,
        'uspTitle': value.uspTitle,
        'usps': value.usps === undefined ? undefined : (value.usps === null ? null : (value.usps as Array<any>).map(UspApiModelToJSON)),
        'orderDetails': OrderDetailApiModelToJSON(value.orderDetails),
    };
}

