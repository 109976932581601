import {
  ProfileApi,
  ProfilePersonalDetailsPutRequest,
  ProfilePasswordPutRequest,
  DefaultConfig,
} from '@groenhart/api';
import { ApiBase } from './base.api';

export function useProfileApi() {
  const runtimeConfig = useRuntimeConfig();
  const headers = useApiHeaders();

  const apiBase = new ApiBase(runtimeConfig.public.apiBase);
  DefaultConfig.config = apiBase.getDefaultConfig();

  const profileApi = new ProfileApi();

  async function getProfile() {
    return await profileApi.profileGet({ headers });
  }

  async function getExtendedProfile(){
    return await profileApi.profileExtendedGet({ headers });
  }

  async function getPersonalDetails() {
    return await profileApi.profilePersonalDetailsGet({ headers });
  }

  async function updatePersonalDetails({
    personalDetailsApiModel,
  }: ProfilePersonalDetailsPutRequest) {
    return await profileApi.profilePersonalDetailsPut({ personalDetailsApiModel }, { headers });
  }

  async function getPasswordChangeRequired(){
    return await profileApi.profileGetPasswordChangeRequiredGet({ headers });
  }

  async function changePassword({ updatePasswordApiRequestModel }: ProfilePasswordPutRequest) {
    return await profileApi.profilePasswordPut({ updatePasswordApiRequestModel }, { headers });
  }

  return {
    getProfile,
    getExtendedProfile,
    getPersonalDetails,
    updatePersonalDetails,
    getPasswordChangeRequired,
    changePassword,
  };
}
