/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface InvoiceAddressApiModel
 */
export interface InvoiceAddressApiModel {
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressApiModel
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressApiModel
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressApiModel
     */
    houseNumberAddition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressApiModel
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressApiModel
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InvoiceAddressApiModel
     */
    country?: string | null;
}

/**
 * Check if a given object implements the InvoiceAddressApiModel interface.
 */
export function instanceOfInvoiceAddressApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function InvoiceAddressApiModelFromJSON(json: any): InvoiceAddressApiModel {
    return InvoiceAddressApiModelFromJSONTyped(json, false);
}

export function InvoiceAddressApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): InvoiceAddressApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'street': !exists(json, 'street') ? undefined : json['street'],
        'houseNumber': !exists(json, 'houseNumber') ? undefined : json['houseNumber'],
        'houseNumberAddition': !exists(json, 'houseNumberAddition') ? undefined : json['houseNumberAddition'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'country': !exists(json, 'country') ? undefined : json['country'],
    };
}

export function InvoiceAddressApiModelToJSON(value?: InvoiceAddressApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'street': value.street,
        'houseNumber': value.houseNumber,
        'houseNumberAddition': value.houseNumberAddition,
        'postalCode': value.postalCode,
        'city': value.city,
        'country': value.country,
    };
}

