/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProfileMember } from './ProfileMember';
import {
    ProfileMemberFromJSON,
    ProfileMemberFromJSONTyped,
    ProfileMemberToJSON,
} from './ProfileMember';

/**
 * 
 * @export
 * @interface ProfileApiModel
 */
export interface ProfileApiModel {
    /**
     * 
     * @type {string}
     * @memberof ProfileApiModel
     */
    favoriteBranche?: string | null;
    /**
     * 
     * @type {ProfileMember}
     * @memberof ProfileApiModel
     */
    member?: ProfileMember;
    /**
     * 
     * @type {string}
     * @memberof ProfileApiModel
     */
    quotesUrl?: string | null;
}

/**
 * Check if a given object implements the ProfileApiModel interface.
 */
export function instanceOfProfileApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfileApiModelFromJSON(json: any): ProfileApiModel {
    return ProfileApiModelFromJSONTyped(json, false);
}

export function ProfileApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProfileApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'favoriteBranche': !exists(json, 'favoriteBranche') ? undefined : json['favoriteBranche'],
        'member': !exists(json, 'member') ? undefined : ProfileMemberFromJSON(json['member']),
        'quotesUrl': !exists(json, 'quotesUrl') ? undefined : json['quotesUrl'],
    };
}

export function ProfileApiModelToJSON(value?: ProfileApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'favoriteBranche': value.favoriteBranche,
        'member': ProfileMemberToJSON(value.member),
        'quotesUrl': value.quotesUrl,
    };
}

