import { defineStore } from 'pinia';
import type { ProfileExtendedApiModel } from '@groenhart/api';

export const useProfileStore = defineStore('profile-store', () => {
  const maxMinutesBeforeUpdate = 15; 
  const lastUpdateDate = ref<Date | null>(null);
  const extendedProfileData = ref<ProfileExtendedApiModel | null>(null);

  function getExtendedProfileData(){
    if (!lastUpdateDate.value){
      return null;
    }

    const currentDate = new Date();
    const minutesSinceLastUpdate = differenceInMinutes(lastUpdateDate.value, currentDate);

    if (minutesSinceLastUpdate >= maxMinutesBeforeUpdate){
      extendedProfileData.value = null;

      return null;
    }

    return extendedProfileData.value;
  }

  function setExtendedProfileData(data: ProfileExtendedApiModel){
    lastUpdateDate.value = new Date();
    extendedProfileData.value = data;
  }

  function clearExtendedProfileData(){
    lastUpdateDate.value = null;
    extendedProfileData.value = null;
  }

  function differenceInMinutes(start: Date, end: Date){
    const inSeconds = (end.getTime() - start.getTime()) / 1000;
    const inMinutes = inSeconds / 60;

    return Math.abs(Math.round(inMinutes));
  }

  return { getExtendedProfileData, setExtendedProfileData, clearExtendedProfileData };
});
