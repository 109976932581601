/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ShipmentApiModelDeliveryTime } from './ShipmentApiModelDeliveryTime';
import {
    ShipmentApiModelDeliveryTimeFromJSON,
    ShipmentApiModelDeliveryTimeFromJSONTyped,
    ShipmentApiModelDeliveryTimeToJSON,
} from './ShipmentApiModelDeliveryTime';

/**
 * 
 * @export
 * @interface ShipmentApiModel
 */
export interface ShipmentApiModel {
    /**
     * 
     * @type {string}
     * @memberof ShipmentApiModel
     */
    deliveryText?: string | null;
    /**
     * 
     * @type {ShipmentApiModelDeliveryTime}
     * @memberof ShipmentApiModel
     */
    deliveryTime?: ShipmentApiModelDeliveryTime;
    /**
     * 
     * @type {Array<string>}
     * @memberof ShipmentApiModel
     */
    skus?: Array<string> | null;
}

/**
 * Check if a given object implements the ShipmentApiModel interface.
 */
export function instanceOfShipmentApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ShipmentApiModelFromJSON(json: any): ShipmentApiModel {
    return ShipmentApiModelFromJSONTyped(json, false);
}

export function ShipmentApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShipmentApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deliveryText': !exists(json, 'deliveryText') ? undefined : json['deliveryText'],
        'deliveryTime': !exists(json, 'deliveryTime') ? undefined : ShipmentApiModelDeliveryTimeFromJSON(json['deliveryTime']),
        'skus': !exists(json, 'skus') ? undefined : json['skus'],
    };
}

export function ShipmentApiModelToJSON(value?: ShipmentApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deliveryText': value.deliveryText,
        'deliveryTime': ShipmentApiModelDeliveryTimeToJSON(value.deliveryTime),
        'skus': value.skus,
    };
}

