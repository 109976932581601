/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ContentApiModel
 */
export interface ContentApiModel {
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    activateAccountNoInvoiceMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    insufficientStockMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    dashboardOrderDetailPopup?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    receiveCustomerCardText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noCustomerCardText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    changePasswordText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    changePasswordButtonText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    myGroenhart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productShareText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    basketShippingExplanation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productScannerManualInputLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addToBasketSticky?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    forgotPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    login?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    loginShort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    createAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noAccountYet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    whyLogin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    loginForPriceDeals?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    profile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    closestLocationCardTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    category?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    categories?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    brochuresTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productsTitleLoggedIn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productsTitleLoggedOut?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    allDeals?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    brochure?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    assortment?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    moreInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    continueAsGuest?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    back?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    backToHome?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newsMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newsMessages?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    nextNewsmessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    foundProducts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    filter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    filters?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    _delete?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    toFilter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    showProducts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    sortBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    subNewsletter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    saleInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    buttonSendWhatsapp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    buttonCallLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    buttonSendEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    openingHours?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    comeAndGo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    searchEmptyPlaceholder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    searchBarPlaceholder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    recentlySearched?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    showAllProducts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productPictures?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emptyCartHeading?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emptyCart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emptyCartAssortimentButton?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emptyCartSalesButton?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    titleCart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    inclVat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    exclVat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    add?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    minus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    checkDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addToBasket?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addToOrderList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    remove?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    subTotal?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    discountCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    vat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    shipping?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    totalPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    totalAmount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    free?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    continueOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    continueShopping?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addToOrderListOverlay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    copyToOrderList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    deleteCart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    annuleren?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    allProductsDeleted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    voucherOrDiscount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    enterDiscount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    applyDiscount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    delivery?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    amount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    inStock?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    inStockIn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    articleStock?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    notInStock?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    next?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    save?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    extra?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    showMore?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    showLess?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    date?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    quantity?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    send?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    confirmPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    resetPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    groenhartLocations?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    closed?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    edit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    _continue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    searchBarPlaceholderLocations?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noLocationsFound?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    confirm?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    vatNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    relatedProducts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    searchMoreCharacters?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    invalidQuantityMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    naam?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    actions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    alter?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    projects?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    archiving?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    archived?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    removeFromArchive?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addProject?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    see?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productAddedBasket?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productAddedOrderList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productRemovedBasket?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    productRemovedOrderList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    adresChanged?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newAdresAdded?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    deleteAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    editAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    deleteAddressConfirmation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addedToWishlist?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    somethingWentWrong?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    changePersonalDetailsSuccess?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    changePasswordSuccess?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    successfullyImportListToCart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    projectAdded?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    projectDeleted?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    generalFormError?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    somethingWentWrongTryAgain?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    deliveryNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    order?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    personalDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    companyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    firstName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    lastName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    deliveryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    street?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    kvkNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    infix?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    phoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    postalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    houseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addition?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    address?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    items?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    item?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    confirmOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    sendOffers?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    acceptTerms?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    deliveryMethod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    terms?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    extraOrderDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    purchaseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    checkOutProjects?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    remarks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    work?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    remarksPlaceholder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addNewAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    checkoutName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    checkoutInvoiceAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    paymentMethod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    selectBank?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    selectCredit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    handleOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderConfirmationMail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    weCheckOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    messageWhenOrderReady?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    backToHomePage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    checkoutCreateAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    createAccountIntro?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    advantagesOfAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    buildOnUs?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    mobile?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    validationPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    validationPhoneNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    validationEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    validationPostalCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    validationHouseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    validationPurchaseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    validationKvkNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    validationCompanyName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    logout?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    favoriteLocation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    myOrders?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orders?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderLists?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderHistory?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    acccountName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    adresses?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    invoices?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    customerService?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    frequentlyAskedQuestions?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    requestQuote?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    last30Days?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderPrice?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderStatus?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    placeOrder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    invoiceAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    myDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    myAddresses?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    billingAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    accountDeliveryAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    saveAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    defaultAddressLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    setAsDefaultAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    changeBillingAddressInfo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    logoutConfirmation?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    invoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    pay?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    helpTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    helpText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    openInvoices?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    paidInvoices?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    paymentSuccesful?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    openInvoiceMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    currentPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    saveDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    changePassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    help?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    paymentUnsuccessful?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    needHelp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    alreadyHaveAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    register?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    passwordRequirements?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    registerConfirmPassword?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    loginDetails?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    invoicing?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    invoicingEmail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newsletterAndPrivary?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    signUp?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newCustomer?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    registerCheckoutIntro?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    existingClient?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    fillInEmailToContinue?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    activateAccountIntro?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newToGroenhart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    activateAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    clientNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    lastInvoiceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noInvoiceYet?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    preferredPaymentMethod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    toNewsMessages?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    activateYourAccount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    clientNrNotFound?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emailAlreadyInUse?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    privacyDecleration?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    acceptPrivacyTerms?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    customerCard?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    header?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    toShop?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    body?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    headerActivated?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    headerRegisterSuccess?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    scanQRorBarcode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    scannerIntro?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    typeInArticleNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    permissionNeededMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    permissionTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    permissionIntro?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    permissionButton?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    thisIsHowItWorks?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    help1?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    help2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    articleNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    articleNumberPlaceholder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    articleNumberLabel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    scannerNotFound?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    addAllToCart?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    createNewList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emptyListHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emptyListHeader2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emptyListMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emptyListButton?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    editNameTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    inputPlaceholder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    paginaTitel?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    removeAllProducts?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    removeList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    newWishlist?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noListHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noListHeader2?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noListMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    products?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    shareList?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    searchPlaceholder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noSearchResultsHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noSearchResultsMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    checkBasket?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderHistoryNoResultsText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderHistoryNoResultsTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderHistoryNoResultsHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    infoTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    pendingText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    processingText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    reservedText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    backOrderText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    inTransitText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    deliveredText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    billedText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    specificationText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    ordersOrderedBy?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    ordersTotalAmount?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    ordersDeliveryMethod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderNotFound?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    orderNotFoundMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    backToMyOrders?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    overlayTitle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noResults?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    confirmMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    confirmButton?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    removeAccountBtn?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    title500?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    message500?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    title404?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    message404?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noInvoicesText?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    noInvoicesHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    contactsHeader?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    turnOnLocationMessage?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    nextOpeningTimeFormat?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    emailNotReceived?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    forgotPasswordIntro?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ContentApiModel
     */
    resetPasswordIntro?: string | null;
}

/**
 * Check if a given object implements the ContentApiModel interface.
 */
export function instanceOfContentApiModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ContentApiModelFromJSON(json: any): ContentApiModel {
    return ContentApiModelFromJSONTyped(json, false);
}

export function ContentApiModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContentApiModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'activateAccountNoInvoiceMessage': !exists(json, 'activateAccountNoInvoiceMessage') ? undefined : json['activateAccountNoInvoiceMessage'],
        'insufficientStockMessage': !exists(json, 'insufficientStockMessage') ? undefined : json['insufficientStockMessage'],
        'dashboardOrderDetailPopup': !exists(json, 'dashboardOrderDetailPopup') ? undefined : json['dashboardOrderDetailPopup'],
        'receiveCustomerCardText': !exists(json, 'receiveCustomerCardText') ? undefined : json['receiveCustomerCardText'],
        'noCustomerCardText': !exists(json, 'noCustomerCardText') ? undefined : json['noCustomerCardText'],
        'changePasswordText': !exists(json, 'changePasswordText') ? undefined : json['changePasswordText'],
        'changePasswordButtonText': !exists(json, 'changePasswordButtonText') ? undefined : json['changePasswordButtonText'],
        'myGroenhart': !exists(json, 'myGroenhart') ? undefined : json['myGroenhart'],
        'productShareText': !exists(json, 'productShareText') ? undefined : json['productShareText'],
        'basketShippingExplanation': !exists(json, 'basketShippingExplanation') ? undefined : json['basketShippingExplanation'],
        'productScannerManualInputLabel': !exists(json, 'productScannerManualInputLabel') ? undefined : json['productScannerManualInputLabel'],
        'addToBasketSticky': !exists(json, 'addToBasketSticky') ? undefined : json['addToBasketSticky'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'forgotPassword': !exists(json, 'forgotPassword') ? undefined : json['forgotPassword'],
        'login': !exists(json, 'login') ? undefined : json['login'],
        'loginShort': !exists(json, 'loginShort') ? undefined : json['loginShort'],
        'createAccount': !exists(json, 'createAccount') ? undefined : json['createAccount'],
        'noAccountYet': !exists(json, 'noAccountYet') ? undefined : json['noAccountYet'],
        'whyLogin': !exists(json, 'whyLogin') ? undefined : json['whyLogin'],
        'loginForPriceDeals': !exists(json, 'loginForPriceDeals') ? undefined : json['loginForPriceDeals'],
        'profile': !exists(json, 'profile') ? undefined : json['profile'],
        'closestLocationCardTitle': !exists(json, 'closestLocationCardTitle') ? undefined : json['closestLocationCardTitle'],
        'category': !exists(json, 'category') ? undefined : json['category'],
        'categories': !exists(json, 'categories') ? undefined : json['categories'],
        'brochuresTitle': !exists(json, 'brochuresTitle') ? undefined : json['brochuresTitle'],
        'productsTitleLoggedIn': !exists(json, 'productsTitleLoggedIn') ? undefined : json['productsTitleLoggedIn'],
        'productsTitleLoggedOut': !exists(json, 'productsTitleLoggedOut') ? undefined : json['productsTitleLoggedOut'],
        'allDeals': !exists(json, 'allDeals') ? undefined : json['allDeals'],
        'brochure': !exists(json, 'brochure') ? undefined : json['brochure'],
        'assortment': !exists(json, 'assortment') ? undefined : json['assortment'],
        'moreInfo': !exists(json, 'moreInfo') ? undefined : json['moreInfo'],
        'continueAsGuest': !exists(json, 'continueAsGuest') ? undefined : json['continueAsGuest'],
        'back': !exists(json, 'back') ? undefined : json['back'],
        'backToHome': !exists(json, 'backToHome') ? undefined : json['backToHome'],
        'newsMessage': !exists(json, 'newsMessage') ? undefined : json['newsMessage'],
        'newsMessages': !exists(json, 'newsMessages') ? undefined : json['newsMessages'],
        'nextNewsmessage': !exists(json, 'nextNewsmessage') ? undefined : json['nextNewsmessage'],
        'foundProducts': !exists(json, 'foundProducts') ? undefined : json['foundProducts'],
        'filter': !exists(json, 'filter') ? undefined : json['filter'],
        'filters': !exists(json, 'filters') ? undefined : json['filters'],
        '_delete': !exists(json, 'delete') ? undefined : json['delete'],
        'toFilter': !exists(json, 'toFilter') ? undefined : json['toFilter'],
        'showProducts': !exists(json, 'showProducts') ? undefined : json['showProducts'],
        'sortBy': !exists(json, 'sortBy') ? undefined : json['sortBy'],
        'subNewsletter': !exists(json, 'subNewsletter') ? undefined : json['subNewsletter'],
        'saleInfo': !exists(json, 'saleInfo') ? undefined : json['saleInfo'],
        'buttonSendWhatsapp': !exists(json, 'buttonSendWhatsapp') ? undefined : json['buttonSendWhatsapp'],
        'buttonCallLocation': !exists(json, 'buttonCallLocation') ? undefined : json['buttonCallLocation'],
        'buttonSendEmail': !exists(json, 'buttonSendEmail') ? undefined : json['buttonSendEmail'],
        'openingHours': !exists(json, 'openingHours') ? undefined : json['openingHours'],
        'comeAndGo': !exists(json, 'comeAndGo') ? undefined : json['comeAndGo'],
        'searchEmptyPlaceholder': !exists(json, 'searchEmptyPlaceholder') ? undefined : json['searchEmptyPlaceholder'],
        'searchBarPlaceholder': !exists(json, 'searchBarPlaceholder') ? undefined : json['searchBarPlaceholder'],
        'recentlySearched': !exists(json, 'recentlySearched') ? undefined : json['recentlySearched'],
        'showAllProducts': !exists(json, 'showAllProducts') ? undefined : json['showAllProducts'],
        'productPictures': !exists(json, 'productPictures') ? undefined : json['productPictures'],
        'emptyCartHeading': !exists(json, 'emptyCartHeading') ? undefined : json['emptyCartHeading'],
        'emptyCart': !exists(json, 'emptyCart') ? undefined : json['emptyCart'],
        'emptyCartAssortimentButton': !exists(json, 'emptyCartAssortimentButton') ? undefined : json['emptyCartAssortimentButton'],
        'emptyCartSalesButton': !exists(json, 'emptyCartSalesButton') ? undefined : json['emptyCartSalesButton'],
        'titleCart': !exists(json, 'titleCart') ? undefined : json['titleCart'],
        'inclVat': !exists(json, 'inclVat') ? undefined : json['inclVat'],
        'exclVat': !exists(json, 'exclVat') ? undefined : json['exclVat'],
        'add': !exists(json, 'add') ? undefined : json['add'],
        'minus': !exists(json, 'minus') ? undefined : json['minus'],
        'checkDetails': !exists(json, 'checkDetails') ? undefined : json['checkDetails'],
        'addToBasket': !exists(json, 'addToBasket') ? undefined : json['addToBasket'],
        'addToOrderList': !exists(json, 'addToOrderList') ? undefined : json['addToOrderList'],
        'remove': !exists(json, 'remove') ? undefined : json['remove'],
        'subTotal': !exists(json, 'subTotal') ? undefined : json['subTotal'],
        'discountCode': !exists(json, 'discountCode') ? undefined : json['discountCode'],
        'vat': !exists(json, 'vat') ? undefined : json['vat'],
        'shipping': !exists(json, 'shipping') ? undefined : json['shipping'],
        'totalPrice': !exists(json, 'totalPrice') ? undefined : json['totalPrice'],
        'totalAmount': !exists(json, 'totalAmount') ? undefined : json['totalAmount'],
        'free': !exists(json, 'free') ? undefined : json['free'],
        'continueOrder': !exists(json, 'continueOrder') ? undefined : json['continueOrder'],
        'continueShopping': !exists(json, 'continueShopping') ? undefined : json['continueShopping'],
        'addToOrderListOverlay': !exists(json, 'addToOrderListOverlay') ? undefined : json['addToOrderListOverlay'],
        'copyToOrderList': !exists(json, 'copyToOrderList') ? undefined : json['copyToOrderList'],
        'deleteCart': !exists(json, 'deleteCart') ? undefined : json['deleteCart'],
        'annuleren': !exists(json, 'annuleren') ? undefined : json['annuleren'],
        'allProductsDeleted': !exists(json, 'allProductsDeleted') ? undefined : json['allProductsDeleted'],
        'voucherOrDiscount': !exists(json, 'voucherOrDiscount') ? undefined : json['voucherOrDiscount'],
        'enterDiscount': !exists(json, 'enterDiscount') ? undefined : json['enterDiscount'],
        'applyDiscount': !exists(json, 'applyDiscount') ? undefined : json['applyDiscount'],
        'delivery': !exists(json, 'delivery') ? undefined : json['delivery'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'inStock': !exists(json, 'inStock') ? undefined : json['inStock'],
        'inStockIn': !exists(json, 'inStockIn') ? undefined : json['inStockIn'],
        'articleStock': !exists(json, 'articleStock') ? undefined : json['articleStock'],
        'notInStock': !exists(json, 'notInStock') ? undefined : json['notInStock'],
        'next': !exists(json, 'next') ? undefined : json['next'],
        'save': !exists(json, 'save') ? undefined : json['save'],
        'extra': !exists(json, 'extra') ? undefined : json['extra'],
        'showMore': !exists(json, 'showMore') ? undefined : json['showMore'],
        'showLess': !exists(json, 'showLess') ? undefined : json['showLess'],
        'date': !exists(json, 'date') ? undefined : json['date'],
        'quantity': !exists(json, 'quantity') ? undefined : json['quantity'],
        'send': !exists(json, 'send') ? undefined : json['send'],
        'confirmPassword': !exists(json, 'confirmPassword') ? undefined : json['confirmPassword'],
        'resetPassword': !exists(json, 'resetPassword') ? undefined : json['resetPassword'],
        'groenhartLocations': !exists(json, 'groenhartLocations') ? undefined : json['groenhartLocations'],
        'closed': !exists(json, 'closed') ? undefined : json['closed'],
        'edit': !exists(json, 'edit') ? undefined : json['edit'],
        'emailAddress': !exists(json, 'emailAddress') ? undefined : json['emailAddress'],
        '_continue': !exists(json, 'continue') ? undefined : json['continue'],
        'searchBarPlaceholderLocations': !exists(json, 'searchBarPlaceholderLocations') ? undefined : json['searchBarPlaceholderLocations'],
        'noLocationsFound': !exists(json, 'noLocationsFound') ? undefined : json['noLocationsFound'],
        'confirm': !exists(json, 'confirm') ? undefined : json['confirm'],
        'vatNumber': !exists(json, 'vatNumber') ? undefined : json['vatNumber'],
        'relatedProducts': !exists(json, 'relatedProducts') ? undefined : json['relatedProducts'],
        'searchMoreCharacters': !exists(json, 'searchMoreCharacters') ? undefined : json['searchMoreCharacters'],
        'invalidQuantityMessage': !exists(json, 'invalidQuantityMessage') ? undefined : json['invalidQuantityMessage'],
        'naam': !exists(json, 'naam') ? undefined : json['naam'],
        'actions': !exists(json, 'actions') ? undefined : json['actions'],
        'alter': !exists(json, 'alter') ? undefined : json['alter'],
        'projects': !exists(json, 'projects') ? undefined : json['projects'],
        'archiving': !exists(json, 'archiving') ? undefined : json['archiving'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'removeFromArchive': !exists(json, 'removeFromArchive') ? undefined : json['removeFromArchive'],
        'addProject': !exists(json, 'addProject') ? undefined : json['addProject'],
        'see': !exists(json, 'see') ? undefined : json['see'],
        'productAddedBasket': !exists(json, 'productAddedBasket') ? undefined : json['productAddedBasket'],
        'productAddedOrderList': !exists(json, 'productAddedOrderList') ? undefined : json['productAddedOrderList'],
        'productRemovedBasket': !exists(json, 'productRemovedBasket') ? undefined : json['productRemovedBasket'],
        'productRemovedOrderList': !exists(json, 'productRemovedOrderList') ? undefined : json['productRemovedOrderList'],
        'adresChanged': !exists(json, 'adresChanged') ? undefined : json['adresChanged'],
        'newAdresAdded': !exists(json, 'newAdresAdded') ? undefined : json['newAdresAdded'],
        'deleteAddress': !exists(json, 'deleteAddress') ? undefined : json['deleteAddress'],
        'editAddress': !exists(json, 'editAddress') ? undefined : json['editAddress'],
        'deleteAddressConfirmation': !exists(json, 'deleteAddressConfirmation') ? undefined : json['deleteAddressConfirmation'],
        'addedToWishlist': !exists(json, 'addedToWishlist') ? undefined : json['addedToWishlist'],
        'somethingWentWrong': !exists(json, 'somethingWentWrong') ? undefined : json['somethingWentWrong'],
        'changePersonalDetailsSuccess': !exists(json, 'changePersonalDetailsSuccess') ? undefined : json['changePersonalDetailsSuccess'],
        'changePasswordSuccess': !exists(json, 'changePasswordSuccess') ? undefined : json['changePasswordSuccess'],
        'successfullyImportListToCart': !exists(json, 'successfullyImportListToCart') ? undefined : json['successfullyImportListToCart'],
        'projectAdded': !exists(json, 'projectAdded') ? undefined : json['projectAdded'],
        'projectDeleted': !exists(json, 'projectDeleted') ? undefined : json['projectDeleted'],
        'generalFormError': !exists(json, 'generalFormError') ? undefined : json['generalFormError'],
        'somethingWentWrongTryAgain': !exists(json, 'somethingWentWrongTryAgain') ? undefined : json['somethingWentWrongTryAgain'],
        'deliveryNumber': !exists(json, 'deliveryNumber') ? undefined : json['deliveryNumber'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'personalDetails': !exists(json, 'personalDetails') ? undefined : json['personalDetails'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'deliveryAddress': !exists(json, 'deliveryAddress') ? undefined : json['deliveryAddress'],
        'street': !exists(json, 'street') ? undefined : json['street'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'kvkNumber': !exists(json, 'kvkNumber') ? undefined : json['kvkNumber'],
        'infix': !exists(json, 'infix') ? undefined : json['infix'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
        'postalCode': !exists(json, 'postalCode') ? undefined : json['postalCode'],
        'houseNumber': !exists(json, 'houseNumber') ? undefined : json['houseNumber'],
        'addition': !exists(json, 'addition') ? undefined : json['addition'],
        'address': !exists(json, 'address') ? undefined : json['address'],
        'orderDetails': !exists(json, 'orderDetails') ? undefined : json['orderDetails'],
        'items': !exists(json, 'items') ? undefined : json['items'],
        'item': !exists(json, 'item') ? undefined : json['item'],
        'confirmOrder': !exists(json, 'confirmOrder') ? undefined : json['confirmOrder'],
        'sendOffers': !exists(json, 'sendOffers') ? undefined : json['sendOffers'],
        'acceptTerms': !exists(json, 'acceptTerms') ? undefined : json['acceptTerms'],
        'deliveryMethod': !exists(json, 'deliveryMethod') ? undefined : json['deliveryMethod'],
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'extraOrderDetails': !exists(json, 'extraOrderDetails') ? undefined : json['extraOrderDetails'],
        'purchaseNumber': !exists(json, 'purchaseNumber') ? undefined : json['purchaseNumber'],
        'checkOutProjects': !exists(json, 'checkOutProjects') ? undefined : json['checkOutProjects'],
        'orderedBy': !exists(json, 'orderedBy') ? undefined : json['orderedBy'],
        'remarks': !exists(json, 'remarks') ? undefined : json['remarks'],
        'work': !exists(json, 'work') ? undefined : json['work'],
        'remarksPlaceholder': !exists(json, 'remarksPlaceholder') ? undefined : json['remarksPlaceholder'],
        'addNewAddress': !exists(json, 'addNewAddress') ? undefined : json['addNewAddress'],
        'checkoutName': !exists(json, 'checkoutName') ? undefined : json['checkoutName'],
        'checkoutInvoiceAddress': !exists(json, 'checkoutInvoiceAddress') ? undefined : json['checkoutInvoiceAddress'],
        'paymentMethod': !exists(json, 'paymentMethod') ? undefined : json['paymentMethod'],
        'selectBank': !exists(json, 'selectBank') ? undefined : json['selectBank'],
        'selectCredit': !exists(json, 'selectCredit') ? undefined : json['selectCredit'],
        'handleOrder': !exists(json, 'handleOrder') ? undefined : json['handleOrder'],
        'orderConfirmationMail': !exists(json, 'orderConfirmationMail') ? undefined : json['orderConfirmationMail'],
        'weCheckOrder': !exists(json, 'weCheckOrder') ? undefined : json['weCheckOrder'],
        'messageWhenOrderReady': !exists(json, 'messageWhenOrderReady') ? undefined : json['messageWhenOrderReady'],
        'backToHomePage': !exists(json, 'backToHomePage') ? undefined : json['backToHomePage'],
        'checkoutCreateAccount': !exists(json, 'checkoutCreateAccount') ? undefined : json['checkoutCreateAccount'],
        'createAccountIntro': !exists(json, 'createAccountIntro') ? undefined : json['createAccountIntro'],
        'advantagesOfAccount': !exists(json, 'advantagesOfAccount') ? undefined : json['advantagesOfAccount'],
        'buildOnUs': !exists(json, 'buildOnUs') ? undefined : json['buildOnUs'],
        'mobile': !exists(json, 'mobile') ? undefined : json['mobile'],
        'validationPassword': !exists(json, 'validationPassword') ? undefined : json['validationPassword'],
        'validationPhoneNumber': !exists(json, 'validationPhoneNumber') ? undefined : json['validationPhoneNumber'],
        'validationEmail': !exists(json, 'validationEmail') ? undefined : json['validationEmail'],
        'validationPostalCode': !exists(json, 'validationPostalCode') ? undefined : json['validationPostalCode'],
        'validationHouseNumber': !exists(json, 'validationHouseNumber') ? undefined : json['validationHouseNumber'],
        'validationPurchaseNumber': !exists(json, 'validationPurchaseNumber') ? undefined : json['validationPurchaseNumber'],
        'validationKvkNumber': !exists(json, 'validationKvkNumber') ? undefined : json['validationKvkNumber'],
        'validationCompanyName': !exists(json, 'validationCompanyName') ? undefined : json['validationCompanyName'],
        'logout': !exists(json, 'logout') ? undefined : json['logout'],
        'favoriteLocation': !exists(json, 'favoriteLocation') ? undefined : json['favoriteLocation'],
        'myOrders': !exists(json, 'myOrders') ? undefined : json['myOrders'],
        'orders': !exists(json, 'orders') ? undefined : json['orders'],
        'orderLists': !exists(json, 'orderLists') ? undefined : json['orderLists'],
        'orderHistory': !exists(json, 'orderHistory') ? undefined : json['orderHistory'],
        'acccountName': !exists(json, 'acccountName') ? undefined : json['acccountName'],
        'adresses': !exists(json, 'adresses') ? undefined : json['adresses'],
        'invoices': !exists(json, 'invoices') ? undefined : json['invoices'],
        'customerService': !exists(json, 'customerService') ? undefined : json['customerService'],
        'frequentlyAskedQuestions': !exists(json, 'frequentlyAskedQuestions') ? undefined : json['frequentlyAskedQuestions'],
        'requestQuote': !exists(json, 'requestQuote') ? undefined : json['requestQuote'],
        'last30Days': !exists(json, 'last30Days') ? undefined : json['last30Days'],
        'orderNumber': !exists(json, 'orderNumber') ? undefined : json['orderNumber'],
        'orderPrice': !exists(json, 'orderPrice') ? undefined : json['orderPrice'],
        'orderStatus': !exists(json, 'orderStatus') ? undefined : json['orderStatus'],
        'placeOrder': !exists(json, 'placeOrder') ? undefined : json['placeOrder'],
        'invoiceAddress': !exists(json, 'invoiceAddress') ? undefined : json['invoiceAddress'],
        'myDetails': !exists(json, 'myDetails') ? undefined : json['myDetails'],
        'myAddresses': !exists(json, 'myAddresses') ? undefined : json['myAddresses'],
        'billingAddress': !exists(json, 'billingAddress') ? undefined : json['billingAddress'],
        'accountDeliveryAddress': !exists(json, 'accountDeliveryAddress') ? undefined : json['accountDeliveryAddress'],
        'saveAddress': !exists(json, 'saveAddress') ? undefined : json['saveAddress'],
        'defaultAddressLabel': !exists(json, 'defaultAddressLabel') ? undefined : json['defaultAddressLabel'],
        'setAsDefaultAddress': !exists(json, 'setAsDefaultAddress') ? undefined : json['setAsDefaultAddress'],
        'changeBillingAddressInfo': !exists(json, 'changeBillingAddressInfo') ? undefined : json['changeBillingAddressInfo'],
        'logoutConfirmation': !exists(json, 'logoutConfirmation') ? undefined : json['logoutConfirmation'],
        'invoiceNumber': !exists(json, 'invoiceNumber') ? undefined : json['invoiceNumber'],
        'pay': !exists(json, 'pay') ? undefined : json['pay'],
        'helpTitle': !exists(json, 'helpTitle') ? undefined : json['helpTitle'],
        'helpText': !exists(json, 'helpText') ? undefined : json['helpText'],
        'openInvoices': !exists(json, 'openInvoices') ? undefined : json['openInvoices'],
        'paidInvoices': !exists(json, 'paidInvoices') ? undefined : json['paidInvoices'],
        'paymentSuccesful': !exists(json, 'paymentSuccesful') ? undefined : json['paymentSuccesful'],
        'openInvoiceMessage': !exists(json, 'openInvoiceMessage') ? undefined : json['openInvoiceMessage'],
        'currentPassword': !exists(json, 'currentPassword') ? undefined : json['currentPassword'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'saveDetails': !exists(json, 'saveDetails') ? undefined : json['saveDetails'],
        'changePassword': !exists(json, 'changePassword') ? undefined : json['changePassword'],
        'help': !exists(json, 'help') ? undefined : json['help'],
        'paymentUnsuccessful': !exists(json, 'paymentUnsuccessful') ? undefined : json['paymentUnsuccessful'],
        'needHelp': !exists(json, 'needHelp') ? undefined : json['needHelp'],
        'alreadyHaveAccount': !exists(json, 'alreadyHaveAccount') ? undefined : json['alreadyHaveAccount'],
        'register': !exists(json, 'register') ? undefined : json['register'],
        'passwordRequirements': !exists(json, 'passwordRequirements') ? undefined : json['passwordRequirements'],
        'registerConfirmPassword': !exists(json, 'registerConfirmPassword') ? undefined : json['registerConfirmPassword'],
        'loginDetails': !exists(json, 'loginDetails') ? undefined : json['loginDetails'],
        'country': !exists(json, 'country') ? undefined : json['country'],
        'invoicing': !exists(json, 'invoicing') ? undefined : json['invoicing'],
        'invoicingEmail': !exists(json, 'invoicingEmail') ? undefined : json['invoicingEmail'],
        'newsletterAndPrivary': !exists(json, 'newsletterAndPrivary') ? undefined : json['newsletterAndPrivary'],
        'signUp': !exists(json, 'signUp') ? undefined : json['signUp'],
        'newCustomer': !exists(json, 'newCustomer') ? undefined : json['newCustomer'],
        'registerCheckoutIntro': !exists(json, 'registerCheckoutIntro') ? undefined : json['registerCheckoutIntro'],
        'existingClient': !exists(json, 'existingClient') ? undefined : json['existingClient'],
        'fillInEmailToContinue': !exists(json, 'fillInEmailToContinue') ? undefined : json['fillInEmailToContinue'],
        'activateAccountIntro': !exists(json, 'activateAccountIntro') ? undefined : json['activateAccountIntro'],
        'newToGroenhart': !exists(json, 'newToGroenhart') ? undefined : json['newToGroenhart'],
        'activateAccount': !exists(json, 'activateAccount') ? undefined : json['activateAccount'],
        'clientNumber': !exists(json, 'clientNumber') ? undefined : json['clientNumber'],
        'lastInvoiceNumber': !exists(json, 'lastInvoiceNumber') ? undefined : json['lastInvoiceNumber'],
        'noInvoiceYet': !exists(json, 'noInvoiceYet') ? undefined : json['noInvoiceYet'],
        'preferredPaymentMethod': !exists(json, 'preferredPaymentMethod') ? undefined : json['preferredPaymentMethod'],
        'toNewsMessages': !exists(json, 'toNewsMessages') ? undefined : json['toNewsMessages'],
        'activateYourAccount': !exists(json, 'activateYourAccount') ? undefined : json['activateYourAccount'],
        'clientNrNotFound': !exists(json, 'clientNrNotFound') ? undefined : json['clientNrNotFound'],
        'emailAlreadyInUse': !exists(json, 'emailAlreadyInUse') ? undefined : json['emailAlreadyInUse'],
        'privacyDecleration': !exists(json, 'privacyDecleration') ? undefined : json['privacyDecleration'],
        'acceptPrivacyTerms': !exists(json, 'acceptPrivacyTerms') ? undefined : json['acceptPrivacyTerms'],
        'customerCard': !exists(json, 'customerCard') ? undefined : json['customerCard'],
        'header': !exists(json, 'header') ? undefined : json['header'],
        'toShop': !exists(json, 'toShop') ? undefined : json['toShop'],
        'body': !exists(json, 'body') ? undefined : json['body'],
        'headerActivated': !exists(json, 'headerActivated') ? undefined : json['headerActivated'],
        'headerRegisterSuccess': !exists(json, 'headerRegisterSuccess') ? undefined : json['headerRegisterSuccess'],
        'scanQRorBarcode': !exists(json, 'scanQRorBarcode') ? undefined : json['scanQRorBarcode'],
        'scannerIntro': !exists(json, 'scannerIntro') ? undefined : json['scannerIntro'],
        'typeInArticleNumber': !exists(json, 'typeInArticleNumber') ? undefined : json['typeInArticleNumber'],
        'permissionNeededMessage': !exists(json, 'permissionNeededMessage') ? undefined : json['permissionNeededMessage'],
        'permissionTitle': !exists(json, 'permissionTitle') ? undefined : json['permissionTitle'],
        'permissionIntro': !exists(json, 'permissionIntro') ? undefined : json['permissionIntro'],
        'permissionButton': !exists(json, 'permissionButton') ? undefined : json['permissionButton'],
        'thisIsHowItWorks': !exists(json, 'thisIsHowItWorks') ? undefined : json['thisIsHowItWorks'],
        'help1': !exists(json, 'help1') ? undefined : json['help1'],
        'help2': !exists(json, 'help2') ? undefined : json['help2'],
        'articleNumber': !exists(json, 'articleNumber') ? undefined : json['articleNumber'],
        'articleNumberPlaceholder': !exists(json, 'articleNumberPlaceholder') ? undefined : json['articleNumberPlaceholder'],
        'articleNumberLabel': !exists(json, 'articleNumberLabel') ? undefined : json['articleNumberLabel'],
        'scannerNotFound': !exists(json, 'scannerNotFound') ? undefined : json['scannerNotFound'],
        'addAllToCart': !exists(json, 'addAllToCart') ? undefined : json['addAllToCart'],
        'createNewList': !exists(json, 'createNewList') ? undefined : json['createNewList'],
        'emptyListHeader': !exists(json, 'emptyListHeader') ? undefined : json['emptyListHeader'],
        'emptyListHeader2': !exists(json, 'emptyListHeader2') ? undefined : json['emptyListHeader2'],
        'emptyListMessage': !exists(json, 'emptyListMessage') ? undefined : json['emptyListMessage'],
        'emptyListButton': !exists(json, 'emptyListButton') ? undefined : json['emptyListButton'],
        'editNameTitle': !exists(json, 'editNameTitle') ? undefined : json['editNameTitle'],
        'inputPlaceholder': !exists(json, 'inputPlaceholder') ? undefined : json['inputPlaceholder'],
        'paginaTitel': !exists(json, 'paginaTitel') ? undefined : json['paginaTitel'],
        'removeAllProducts': !exists(json, 'removeAllProducts') ? undefined : json['removeAllProducts'],
        'removeList': !exists(json, 'removeList') ? undefined : json['removeList'],
        'newList': !exists(json, 'newList') ? undefined : json['newList'],
        'newWishlist': !exists(json, 'newWishlist') ? undefined : json['newWishlist'],
        'noListHeader': !exists(json, 'noListHeader') ? undefined : json['noListHeader'],
        'noListHeader2': !exists(json, 'noListHeader2') ? undefined : json['noListHeader2'],
        'noListMessage': !exists(json, 'noListMessage') ? undefined : json['noListMessage'],
        'products': !exists(json, 'products') ? undefined : json['products'],
        'shareList': !exists(json, 'shareList') ? undefined : json['shareList'],
        'searchPlaceholder': !exists(json, 'searchPlaceholder') ? undefined : json['searchPlaceholder'],
        'noSearchResultsHeader': !exists(json, 'noSearchResultsHeader') ? undefined : json['noSearchResultsHeader'],
        'noSearchResultsMessage': !exists(json, 'noSearchResultsMessage') ? undefined : json['noSearchResultsMessage'],
        'checkBasket': !exists(json, 'checkBasket') ? undefined : json['checkBasket'],
        'orderHistoryNoResultsText': !exists(json, 'orderHistoryNoResultsText') ? undefined : json['orderHistoryNoResultsText'],
        'orderHistoryNoResultsTitle': !exists(json, 'orderHistoryNoResultsTitle') ? undefined : json['orderHistoryNoResultsTitle'],
        'orderHistoryNoResultsHeader': !exists(json, 'orderHistoryNoResultsHeader') ? undefined : json['orderHistoryNoResultsHeader'],
        'infoTitle': !exists(json, 'infoTitle') ? undefined : json['infoTitle'],
        'pendingText': !exists(json, 'pendingText') ? undefined : json['pendingText'],
        'processingText': !exists(json, 'processingText') ? undefined : json['processingText'],
        'reservedText': !exists(json, 'reservedText') ? undefined : json['reservedText'],
        'backOrderText': !exists(json, 'backOrderText') ? undefined : json['backOrderText'],
        'inTransitText': !exists(json, 'inTransitText') ? undefined : json['inTransitText'],
        'deliveredText': !exists(json, 'deliveredText') ? undefined : json['deliveredText'],
        'billedText': !exists(json, 'billedText') ? undefined : json['billedText'],
        'specificationText': !exists(json, 'specificationText') ? undefined : json['specificationText'],
        'ordersOrderedBy': !exists(json, 'ordersOrderedBy') ? undefined : json['ordersOrderedBy'],
        'ordersTotalAmount': !exists(json, 'ordersTotalAmount') ? undefined : json['ordersTotalAmount'],
        'ordersDeliveryMethod': !exists(json, 'ordersDeliveryMethod') ? undefined : json['ordersDeliveryMethod'],
        'orderDate': !exists(json, 'orderDate') ? undefined : json['orderDate'],
        'orderNotFound': !exists(json, 'orderNotFound') ? undefined : json['orderNotFound'],
        'orderNotFoundMessage': !exists(json, 'orderNotFoundMessage') ? undefined : json['orderNotFoundMessage'],
        'backToMyOrders': !exists(json, 'backToMyOrders') ? undefined : json['backToMyOrders'],
        'overlayTitle': !exists(json, 'overlayTitle') ? undefined : json['overlayTitle'],
        'noResults': !exists(json, 'noResults') ? undefined : json['noResults'],
        'confirmMessage': !exists(json, 'confirmMessage') ? undefined : json['confirmMessage'],
        'confirmButton': !exists(json, 'confirmButton') ? undefined : json['confirmButton'],
        'removeAccountBtn': !exists(json, 'removeAccountBtn') ? undefined : json['removeAccountBtn'],
        'title500': !exists(json, 'title500') ? undefined : json['title500'],
        'message500': !exists(json, 'message500') ? undefined : json['message500'],
        'title404': !exists(json, 'title404') ? undefined : json['title404'],
        'message404': !exists(json, 'message404') ? undefined : json['message404'],
        'noInvoicesText': !exists(json, 'noInvoicesText') ? undefined : json['noInvoicesText'],
        'noInvoicesHeader': !exists(json, 'noInvoicesHeader') ? undefined : json['noInvoicesHeader'],
        'contactsHeader': !exists(json, 'contactsHeader') ? undefined : json['contactsHeader'],
        'turnOnLocationMessage': !exists(json, 'turnOnLocationMessage') ? undefined : json['turnOnLocationMessage'],
        'nextOpeningTimeFormat': !exists(json, 'nextOpeningTimeFormat') ? undefined : json['nextOpeningTimeFormat'],
        'emailNotReceived': !exists(json, 'emailNotReceived') ? undefined : json['emailNotReceived'],
        'forgotPasswordIntro': !exists(json, 'forgotPasswordIntro') ? undefined : json['forgotPasswordIntro'],
        'resetPasswordIntro': !exists(json, 'resetPasswordIntro') ? undefined : json['resetPasswordIntro'],
    };
}

export function ContentApiModelToJSON(value?: ContentApiModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'activateAccountNoInvoiceMessage': value.activateAccountNoInvoiceMessage,
        'insufficientStockMessage': value.insufficientStockMessage,
        'dashboardOrderDetailPopup': value.dashboardOrderDetailPopup,
        'receiveCustomerCardText': value.receiveCustomerCardText,
        'noCustomerCardText': value.noCustomerCardText,
        'changePasswordText': value.changePasswordText,
        'changePasswordButtonText': value.changePasswordButtonText,
        'myGroenhart': value.myGroenhart,
        'productShareText': value.productShareText,
        'basketShippingExplanation': value.basketShippingExplanation,
        'productScannerManualInputLabel': value.productScannerManualInputLabel,
        'addToBasketSticky': value.addToBasketSticky,
        'email': value.email,
        'password': value.password,
        'forgotPassword': value.forgotPassword,
        'login': value.login,
        'loginShort': value.loginShort,
        'createAccount': value.createAccount,
        'noAccountYet': value.noAccountYet,
        'whyLogin': value.whyLogin,
        'loginForPriceDeals': value.loginForPriceDeals,
        'profile': value.profile,
        'closestLocationCardTitle': value.closestLocationCardTitle,
        'category': value.category,
        'categories': value.categories,
        'brochuresTitle': value.brochuresTitle,
        'productsTitleLoggedIn': value.productsTitleLoggedIn,
        'productsTitleLoggedOut': value.productsTitleLoggedOut,
        'allDeals': value.allDeals,
        'brochure': value.brochure,
        'assortment': value.assortment,
        'moreInfo': value.moreInfo,
        'continueAsGuest': value.continueAsGuest,
        'back': value.back,
        'backToHome': value.backToHome,
        'newsMessage': value.newsMessage,
        'newsMessages': value.newsMessages,
        'nextNewsmessage': value.nextNewsmessage,
        'foundProducts': value.foundProducts,
        'filter': value.filter,
        'filters': value.filters,
        'delete': value._delete,
        'toFilter': value.toFilter,
        'showProducts': value.showProducts,
        'sortBy': value.sortBy,
        'subNewsletter': value.subNewsletter,
        'saleInfo': value.saleInfo,
        'buttonSendWhatsapp': value.buttonSendWhatsapp,
        'buttonCallLocation': value.buttonCallLocation,
        'buttonSendEmail': value.buttonSendEmail,
        'openingHours': value.openingHours,
        'comeAndGo': value.comeAndGo,
        'searchEmptyPlaceholder': value.searchEmptyPlaceholder,
        'searchBarPlaceholder': value.searchBarPlaceholder,
        'recentlySearched': value.recentlySearched,
        'showAllProducts': value.showAllProducts,
        'productPictures': value.productPictures,
        'emptyCartHeading': value.emptyCartHeading,
        'emptyCart': value.emptyCart,
        'emptyCartAssortimentButton': value.emptyCartAssortimentButton,
        'emptyCartSalesButton': value.emptyCartSalesButton,
        'titleCart': value.titleCart,
        'inclVat': value.inclVat,
        'exclVat': value.exclVat,
        'add': value.add,
        'minus': value.minus,
        'checkDetails': value.checkDetails,
        'addToBasket': value.addToBasket,
        'addToOrderList': value.addToOrderList,
        'remove': value.remove,
        'subTotal': value.subTotal,
        'discountCode': value.discountCode,
        'vat': value.vat,
        'shipping': value.shipping,
        'totalPrice': value.totalPrice,
        'totalAmount': value.totalAmount,
        'free': value.free,
        'continueOrder': value.continueOrder,
        'continueShopping': value.continueShopping,
        'addToOrderListOverlay': value.addToOrderListOverlay,
        'copyToOrderList': value.copyToOrderList,
        'deleteCart': value.deleteCart,
        'annuleren': value.annuleren,
        'allProductsDeleted': value.allProductsDeleted,
        'voucherOrDiscount': value.voucherOrDiscount,
        'enterDiscount': value.enterDiscount,
        'applyDiscount': value.applyDiscount,
        'delivery': value.delivery,
        'amount': value.amount,
        'inStock': value.inStock,
        'inStockIn': value.inStockIn,
        'articleStock': value.articleStock,
        'notInStock': value.notInStock,
        'next': value.next,
        'save': value.save,
        'extra': value.extra,
        'showMore': value.showMore,
        'showLess': value.showLess,
        'date': value.date,
        'quantity': value.quantity,
        'send': value.send,
        'confirmPassword': value.confirmPassword,
        'resetPassword': value.resetPassword,
        'groenhartLocations': value.groenhartLocations,
        'closed': value.closed,
        'edit': value.edit,
        'emailAddress': value.emailAddress,
        'continue': value._continue,
        'searchBarPlaceholderLocations': value.searchBarPlaceholderLocations,
        'noLocationsFound': value.noLocationsFound,
        'confirm': value.confirm,
        'vatNumber': value.vatNumber,
        'relatedProducts': value.relatedProducts,
        'searchMoreCharacters': value.searchMoreCharacters,
        'invalidQuantityMessage': value.invalidQuantityMessage,
        'naam': value.naam,
        'actions': value.actions,
        'alter': value.alter,
        'projects': value.projects,
        'archiving': value.archiving,
        'archived': value.archived,
        'removeFromArchive': value.removeFromArchive,
        'addProject': value.addProject,
        'see': value.see,
        'productAddedBasket': value.productAddedBasket,
        'productAddedOrderList': value.productAddedOrderList,
        'productRemovedBasket': value.productRemovedBasket,
        'productRemovedOrderList': value.productRemovedOrderList,
        'adresChanged': value.adresChanged,
        'newAdresAdded': value.newAdresAdded,
        'deleteAddress': value.deleteAddress,
        'editAddress': value.editAddress,
        'deleteAddressConfirmation': value.deleteAddressConfirmation,
        'addedToWishlist': value.addedToWishlist,
        'somethingWentWrong': value.somethingWentWrong,
        'changePersonalDetailsSuccess': value.changePersonalDetailsSuccess,
        'changePasswordSuccess': value.changePasswordSuccess,
        'successfullyImportListToCart': value.successfullyImportListToCart,
        'projectAdded': value.projectAdded,
        'projectDeleted': value.projectDeleted,
        'generalFormError': value.generalFormError,
        'somethingWentWrongTryAgain': value.somethingWentWrongTryAgain,
        'deliveryNumber': value.deliveryNumber,
        'order': value.order,
        'personalDetails': value.personalDetails,
        'companyName': value.companyName,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'deliveryAddress': value.deliveryAddress,
        'street': value.street,
        'city': value.city,
        'kvkNumber': value.kvkNumber,
        'infix': value.infix,
        'phoneNumber': value.phoneNumber,
        'postalCode': value.postalCode,
        'houseNumber': value.houseNumber,
        'addition': value.addition,
        'address': value.address,
        'orderDetails': value.orderDetails,
        'items': value.items,
        'item': value.item,
        'confirmOrder': value.confirmOrder,
        'sendOffers': value.sendOffers,
        'acceptTerms': value.acceptTerms,
        'deliveryMethod': value.deliveryMethod,
        'terms': value.terms,
        'status': value.status,
        'extraOrderDetails': value.extraOrderDetails,
        'purchaseNumber': value.purchaseNumber,
        'checkOutProjects': value.checkOutProjects,
        'orderedBy': value.orderedBy,
        'remarks': value.remarks,
        'work': value.work,
        'remarksPlaceholder': value.remarksPlaceholder,
        'addNewAddress': value.addNewAddress,
        'checkoutName': value.checkoutName,
        'checkoutInvoiceAddress': value.checkoutInvoiceAddress,
        'paymentMethod': value.paymentMethod,
        'selectBank': value.selectBank,
        'selectCredit': value.selectCredit,
        'handleOrder': value.handleOrder,
        'orderConfirmationMail': value.orderConfirmationMail,
        'weCheckOrder': value.weCheckOrder,
        'messageWhenOrderReady': value.messageWhenOrderReady,
        'backToHomePage': value.backToHomePage,
        'checkoutCreateAccount': value.checkoutCreateAccount,
        'createAccountIntro': value.createAccountIntro,
        'advantagesOfAccount': value.advantagesOfAccount,
        'buildOnUs': value.buildOnUs,
        'mobile': value.mobile,
        'validationPassword': value.validationPassword,
        'validationPhoneNumber': value.validationPhoneNumber,
        'validationEmail': value.validationEmail,
        'validationPostalCode': value.validationPostalCode,
        'validationHouseNumber': value.validationHouseNumber,
        'validationPurchaseNumber': value.validationPurchaseNumber,
        'validationKvkNumber': value.validationKvkNumber,
        'validationCompanyName': value.validationCompanyName,
        'logout': value.logout,
        'favoriteLocation': value.favoriteLocation,
        'myOrders': value.myOrders,
        'orders': value.orders,
        'orderLists': value.orderLists,
        'orderHistory': value.orderHistory,
        'acccountName': value.acccountName,
        'adresses': value.adresses,
        'invoices': value.invoices,
        'customerService': value.customerService,
        'frequentlyAskedQuestions': value.frequentlyAskedQuestions,
        'requestQuote': value.requestQuote,
        'last30Days': value.last30Days,
        'orderNumber': value.orderNumber,
        'orderPrice': value.orderPrice,
        'orderStatus': value.orderStatus,
        'placeOrder': value.placeOrder,
        'invoiceAddress': value.invoiceAddress,
        'myDetails': value.myDetails,
        'myAddresses': value.myAddresses,
        'billingAddress': value.billingAddress,
        'accountDeliveryAddress': value.accountDeliveryAddress,
        'saveAddress': value.saveAddress,
        'defaultAddressLabel': value.defaultAddressLabel,
        'setAsDefaultAddress': value.setAsDefaultAddress,
        'changeBillingAddressInfo': value.changeBillingAddressInfo,
        'logoutConfirmation': value.logoutConfirmation,
        'invoiceNumber': value.invoiceNumber,
        'pay': value.pay,
        'helpTitle': value.helpTitle,
        'helpText': value.helpText,
        'openInvoices': value.openInvoices,
        'paidInvoices': value.paidInvoices,
        'paymentSuccesful': value.paymentSuccesful,
        'openInvoiceMessage': value.openInvoiceMessage,
        'currentPassword': value.currentPassword,
        'newPassword': value.newPassword,
        'saveDetails': value.saveDetails,
        'changePassword': value.changePassword,
        'help': value.help,
        'paymentUnsuccessful': value.paymentUnsuccessful,
        'needHelp': value.needHelp,
        'alreadyHaveAccount': value.alreadyHaveAccount,
        'register': value.register,
        'passwordRequirements': value.passwordRequirements,
        'registerConfirmPassword': value.registerConfirmPassword,
        'loginDetails': value.loginDetails,
        'country': value.country,
        'invoicing': value.invoicing,
        'invoicingEmail': value.invoicingEmail,
        'newsletterAndPrivary': value.newsletterAndPrivary,
        'signUp': value.signUp,
        'newCustomer': value.newCustomer,
        'registerCheckoutIntro': value.registerCheckoutIntro,
        'existingClient': value.existingClient,
        'fillInEmailToContinue': value.fillInEmailToContinue,
        'activateAccountIntro': value.activateAccountIntro,
        'newToGroenhart': value.newToGroenhart,
        'activateAccount': value.activateAccount,
        'clientNumber': value.clientNumber,
        'lastInvoiceNumber': value.lastInvoiceNumber,
        'noInvoiceYet': value.noInvoiceYet,
        'preferredPaymentMethod': value.preferredPaymentMethod,
        'toNewsMessages': value.toNewsMessages,
        'activateYourAccount': value.activateYourAccount,
        'clientNrNotFound': value.clientNrNotFound,
        'emailAlreadyInUse': value.emailAlreadyInUse,
        'privacyDecleration': value.privacyDecleration,
        'acceptPrivacyTerms': value.acceptPrivacyTerms,
        'customerCard': value.customerCard,
        'header': value.header,
        'toShop': value.toShop,
        'body': value.body,
        'headerActivated': value.headerActivated,
        'headerRegisterSuccess': value.headerRegisterSuccess,
        'scanQRorBarcode': value.scanQRorBarcode,
        'scannerIntro': value.scannerIntro,
        'typeInArticleNumber': value.typeInArticleNumber,
        'permissionNeededMessage': value.permissionNeededMessage,
        'permissionTitle': value.permissionTitle,
        'permissionIntro': value.permissionIntro,
        'permissionButton': value.permissionButton,
        'thisIsHowItWorks': value.thisIsHowItWorks,
        'help1': value.help1,
        'help2': value.help2,
        'articleNumber': value.articleNumber,
        'articleNumberPlaceholder': value.articleNumberPlaceholder,
        'articleNumberLabel': value.articleNumberLabel,
        'scannerNotFound': value.scannerNotFound,
        'addAllToCart': value.addAllToCart,
        'createNewList': value.createNewList,
        'emptyListHeader': value.emptyListHeader,
        'emptyListHeader2': value.emptyListHeader2,
        'emptyListMessage': value.emptyListMessage,
        'emptyListButton': value.emptyListButton,
        'editNameTitle': value.editNameTitle,
        'inputPlaceholder': value.inputPlaceholder,
        'paginaTitel': value.paginaTitel,
        'removeAllProducts': value.removeAllProducts,
        'removeList': value.removeList,
        'newList': value.newList,
        'newWishlist': value.newWishlist,
        'noListHeader': value.noListHeader,
        'noListHeader2': value.noListHeader2,
        'noListMessage': value.noListMessage,
        'products': value.products,
        'shareList': value.shareList,
        'searchPlaceholder': value.searchPlaceholder,
        'noSearchResultsHeader': value.noSearchResultsHeader,
        'noSearchResultsMessage': value.noSearchResultsMessage,
        'checkBasket': value.checkBasket,
        'orderHistoryNoResultsText': value.orderHistoryNoResultsText,
        'orderHistoryNoResultsTitle': value.orderHistoryNoResultsTitle,
        'orderHistoryNoResultsHeader': value.orderHistoryNoResultsHeader,
        'infoTitle': value.infoTitle,
        'pendingText': value.pendingText,
        'processingText': value.processingText,
        'reservedText': value.reservedText,
        'backOrderText': value.backOrderText,
        'inTransitText': value.inTransitText,
        'deliveredText': value.deliveredText,
        'billedText': value.billedText,
        'specificationText': value.specificationText,
        'ordersOrderedBy': value.ordersOrderedBy,
        'ordersTotalAmount': value.ordersTotalAmount,
        'ordersDeliveryMethod': value.ordersDeliveryMethod,
        'orderDate': value.orderDate,
        'orderNotFound': value.orderNotFound,
        'orderNotFoundMessage': value.orderNotFoundMessage,
        'backToMyOrders': value.backToMyOrders,
        'overlayTitle': value.overlayTitle,
        'noResults': value.noResults,
        'confirmMessage': value.confirmMessage,
        'confirmButton': value.confirmButton,
        'removeAccountBtn': value.removeAccountBtn,
        'title500': value.title500,
        'message500': value.message500,
        'title404': value.title404,
        'message404': value.message404,
        'noInvoicesText': value.noInvoicesText,
        'noInvoicesHeader': value.noInvoicesHeader,
        'contactsHeader': value.contactsHeader,
        'turnOnLocationMessage': value.turnOnLocationMessage,
        'nextOpeningTimeFormat': value.nextOpeningTimeFormat,
        'emailNotReceived': value.emailNotReceived,
        'forgotPasswordIntro': value.forgotPasswordIntro,
        'resetPasswordIntro': value.resetPasswordIntro,
    };
}

