<template>
  <div>
    <HeaderMainHeader :has-logo="true" :has-back-button="false" background-color="gray" />
    <LayoutSection padding-block="12" padding-inline="16">
      <FormSearchbar v-model="searchQuery" :has-shadow="true" secondary-icon="scan" />
    </LayoutSection>
    <LayoutSection padding-block="12" padding-inline="16">
      <NuxtErrorBoundary>
        <UspCarousel
          v-if="mappedUsps && mappedUsps.length"
          :slides="mappedUsps"
          icon-color="orange"
          text-color="black" />
        <template #error="{ clearError }">
          <AlertsErrorTryAgain :button-action="clearError" />
        </template>
      </NuxtErrorBoundary>
    </LayoutSection>
    <LayoutSection padding-block="12" padding-inline="16">
      <UspAlert
        v-if="status === 'unauthenticated'"
        :is-closable="true"
        :title="contentStore.content?.whyLogin ?? ''"
        :usps="[
          { icon: 'cart', text: 'Volg jouw bestelling' },
          { icon: 'screw', text: 'Bekijk je eigen prijsafspraken' },
          { icon: 'heart-outline', text: 'Bestel snel opnieuw via je bestelhistorie' },
        ]"
        background-color="orange" />

      <UspAlert
        v-if="passwordChangeRequired === true"
        :is-closable="false"
        :title="contentStore.content?.changePasswordText ?? ''"
        :small-text="true"
        background-color="orange">
          <ButtonsBaseButton
            variant="solid"
            color="white"
            :as-link="true"
            :to="routes.personalDetails.path">
            {{ contentStore.content?.changePasswordButtonText ?? '' }}
          </ButtonsBaseButton>
      </UspAlert>
    </LayoutSection>
    <LayoutSection padding-block="12">
      <LocationsLocationCard
        v-if="favoriteLocation"
        :location="favoriteLocation"
        :is-open="true"
        :carousel-card="false" />
    </LayoutSection>
    <LayoutSection padding-block="12" padding-inline="16">
      <NuxtErrorBoundary>
        <LazyBrochuresCarousel />
        <template #error="{ clearError }">
          <AlertsErrorTryAgain :button-action="clearError" />
        </template>
      </NuxtErrorBoundary>
    </LayoutSection>
    <LayoutSection v-if="!favoriteLocation" padding-block="12" padding-inline="16">
      <NuxtErrorBoundary>
        <LazyLocationsCarousel />
        <template #error="{ clearError }">
          <AlertsErrorTryAgain :button-action="clearError" />
        </template>
      </NuxtErrorBoundary>
    </LayoutSection>
    <LayoutSection padding-block="12" padding-inline="16">
      <NuxtErrorBoundary>
        <LazyProductsProductCarousel
          :is-loading="pendingProducts"
          :products="products?.products ?? []"
          :first-time-order="firstTimeOrder"
          :title="productCarouselTitle" />
        <template #error="{ clearError }">
          <AlertsErrorTryAgain :button-action="clearError" />
        </template>
      </NuxtErrorBoundary>
    </LayoutSection>
    <LayoutSection v-if="!isNewsletterSubscribed" padding-block="12" padding-inline="16">
      <NewsSubscribeNewsCard />
    </LayoutSection>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import type { ProductCardApiModel } from '@groenhart/api';
import type { Usp } from 'types/usp';

const { status } = useAuth();

const { getHomeUsps } = useContentApi();
const { getPasswordChangeRequired } = useProfileApi();
const { getProductCarousel, getRecentlyOrdered } = useProductsApi();
const favoriteLocationStore = useFavoriteLocationStore();
const { favoriteLocation } = storeToRefs(favoriteLocationStore);

const isNewsletterSubscribed = useNewsletter();

interface RecentlyOrderedCarouselProduct {
  products: ProductCardApiModel[];
  recentlyOrdered: boolean;
}

const { data: products, pending: pendingProducts } =
  await useLazyAsyncData<RecentlyOrderedCarouselProduct>('products-carousel', async () => {
    if (status.value === 'authenticated') {
      const recentlyOrdered = await getRecentlyOrdered();
      if (recentlyOrdered?.length) {
        return { products: recentlyOrdered, recentlyOrdered: true };
      } else {
        const products = await getProductCarousel();
        return { products, recentlyOrdered: false };
      }
    } else {
      const products = await getProductCarousel();
      return { products, recentlyOrdered: false };
    }
  });

const { data: usps } = await useAsyncData('home-usps', () => {
  return getHomeUsps();
});

const { data: passwordChangeRequired } = await useLazyAsyncData('password-change-required', async () => {
  if (status.value === 'unauthenticated'){
    return false;
  }

  const result = await getPasswordChangeRequired();
  
  return result.isRequired ?? false;
})

const mappedUsps = computed<Usp[]>(() => {
  if (!usps.value || !usps.value.length) {
    return [];
  }

  return usps.value?.map((usp) => ({
    text: usp.title ?? '',
    icon: usp.icon ?? '',
  })) as Usp[];
});

const searchQuery = ref('');

const contentStore = useContentStore();

const firstTimeOrder = computed(() => {
  return !(
    status.value === 'authenticated' &&
    products.value?.products.length &&
    products.value?.recentlyOrdered
  );
});

const productCarouselTitle = computed(() => {
  return !firstTimeOrder.value
    ? contentStore.content?.productsTitleLoggedIn ?? ''
    : contentStore.content?.productsTitleLoggedOut ?? '';
});
</script>

<style lang="scss">
.usp-slide {
  text-align: center;
}
</style>
