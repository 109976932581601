/* tslint:disable */
/* eslint-disable */
/**
 * Groenhart API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0.39
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RegisterApiResponseModel
 */
export interface RegisterApiResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof RegisterApiResponseModel
     */
    success?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof RegisterApiResponseModel
     */
    validationErrors?: { [key: string]: string; } | null;
    /**
     * 
     * @type {string}
     * @memberof RegisterApiResponseModel
     */
    loginToken?: string | null;
}

/**
 * Check if a given object implements the RegisterApiResponseModel interface.
 */
export function instanceOfRegisterApiResponseModel(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RegisterApiResponseModelFromJSON(json: any): RegisterApiResponseModel {
    return RegisterApiResponseModelFromJSONTyped(json, false);
}

export function RegisterApiResponseModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): RegisterApiResponseModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'validationErrors': !exists(json, 'validationErrors') ? undefined : json['validationErrors'],
        'loginToken': !exists(json, 'loginToken') ? undefined : json['loginToken'],
    };
}

export function RegisterApiResponseModelToJSON(value?: RegisterApiResponseModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'validationErrors': value.validationErrors,
        'loginToken': value.loginToken,
    };
}

